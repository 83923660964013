import Q from "q";
import _ from 'underscore';

import validator_check_phone from "nllib/src/verification/element-ui/el-form/validator_check_phone";
import check_phone from "nllib/src/verification/check_phone";
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";

import userBuyer from "@/lib/data-service/default/web_common_userBuyer_new";
import web_common_userBuyer_register from "@/lib/data-service/default/web_common_userBuyer_register";
import getInlandAreaChildListByParentIdResponse
    from "@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentIdResponse";
import kaptcha from "@/lib/data-service/default/web_common_kaptcha";
import phoneRegister from "@/lib/data-service/default/web_common_register";
import vueCheckUserName from "@/lib/data-service/default/web_common_user_vueCheckUserName";
import web_common_user_checkPhone from "@/lib/data-service/default/web_common_user_checkPhone";
import {STATUS} from "@/lib/deprecated/utils/status";

import liability from "@/page/distributor/other/aboutUs/text/liability";
import knowledge from "@/page/distributor/other/aboutUs/text/knowledge";
import personageComponent from "@/page/supplier/personageRegister";
import userBuyer_findOne from "@/lib/data-service/default/web_common_userBuyer_findOne";
import updateBuyer from "@/lib/data-service/default/web_common_userBuyer_updateBuyer";
import verification from "@/lib/deprecated/utils/verification/1.0.2";

const SUCCESS_CODE = STATUS.success;
export default {
    data() {
        return {
            formData: {
                userName: "",
                areaRegion: "",
                areaRegionName: "",
                businessLicenseNum: "",
                businessLicensePic: "",
                buyerLevel: 0,
                buyerType: "1",
                carryRules: 0,
                commonSeal: "",
                companyAddress: "",
                companyId: 0,
                companyName: "",
                creditId: 0,
                financeFax: "",
                idCard: "",
                idCardFront: "",
                idCardReverse: "",
                officeId: "",
                password: "",
                phone: "",
                smsCode: "",
                touristBusinessLicense: "",
                touristBusinessLicenseNumber: "",
                travelEmail: "",
                travelLinkman: "",
                travelPhone: "",
                travelQq: "",
                areaCitys: [],
            },
            submitLoading:false,
            selectCityStatu: false,
            ossClient: new OssClient(),
            personShow: false,
            protocolText: {
                show: false,
                show1: false,
                text: liability,
                text1: knowledge,
                title: "网站声明",
                title1: "知识产权声明",
            },
            fileListImg: {
                idCardFront: [],
                idCardReverse: [],
                businessLicensePic: [],
                commonSeal: [],
                touristBusinessLicense: [],
            },
            imageUrl: {
                businessLicensePic: '',
                idCardFront: '',
                idCardReverse: '',
                touristBusinessLicense: '',
                commonSeal: '',
            },
            uploadLoading: {
                businessLicensePic: false,
                idCardFront: false,
                idCardReverse: false,
                touristBusinessLicense: false,
                commonSeal: false,
            },
            authCode: {
                url: "",
                value: "",
                show: false,
            },
            getCodePhone: {
                staut: false,
                show: false,
                num: 60,
                text: "获取",
                // text: "获取短信验证码",
            },
            phoneStatu: false,
            agreeStatu: false,
            registrationList: {},
            userType: "distributor-registration",
            siteList: {}, //地址
            options: [],
            password: "",
            rules: {
                //验证
                userName: [
                    {
                        validator: verification.checkUser,
                        trigger: "blur",
                        required: true,
                    },
                ],
                password: [
                    {
                        validator: verification.checkPassWord,
                        trigger: "blur",
                        required: true,
                    },
                ],
                passwordAgain: [
                    {
                        validator: verification.validatePass,
                        trigger: "blur",
                        required: true,
                    },
                ],
                phone: [
                    {
                        validator: validator_check_phone,
                        trigger: "blur",
                        required: true,
                    },
                ],
                smsCode: [{required: true, message: "请输入验证码", trigger: "blur"}],
                businessLicenseNum: [
                    {
                        validator: verification.checkBusinessLicense,
                        trigger: "blur",
                        required: true,
                    },
                ],
                airPhone: [
                    {
                        validator: validator_check_phone,
                        trigger: "blur",
                        required: true,
                    },
                ],
            },
        };
    },
    components: {},
    created() {
        verification.self = this;
    },
    watch: {
        formData: {
            handler(newVal) {
                verification.formData = newVal;
            },
            deep: true,
        },
    },
    methods: {
        getFailReason(val) {
            // this.formData.failReason = val;
            this.$set(this.formData, "failReason", val);
        },
        goRegister(type) {
            type == 3 ? (this.personShow = true) : (this.personShow = false);
            // this.$router.push({ name: path, query: { type: type } });
        },


        //检查用户名
        queryUserName() {
            const name = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
            if (!this.formData.userName) {
                return;
            } else {
                if (!name.test(this.formData.userName)) return;
            }
            vueCheckUserName({userName: this.formData.userName}).then((res) => {
                if (res.code === SUCCESS_CODE) {
                    !res.flag
                        ? this.$message({type: "warning", message: "用户已注册"})
                        : "";
                }
            });
        },
        //获取短信
        getNote() {
            if (!this.formData.phone) {
                return this.$message({type: "warning", message: "请输入手机号码"});
            }

            if (!check_phone({value: this.formData.phone})) {
                return this.$message({
                    type: "warning",
                    message: "电话号码格式不正确",
                });
            }
            this.authCode.value = "";
            this.authCode.show = true;
            kaptcha().then((res) => {
                if (res.code === SUCCESS_CODE) {
                    this.authCode.url = res.img;
                    this.authCode.uuid = res.uuid;
                    this.$refs.authCode.focus();
                }
            });
        },
        //手机验证码
        getPhoneAuthCode() {
            if (!this.formData.phone) {
                return this.$message({type: "warning", message: "请输入手机号码"});
            }
            if (!check_phone({value: this.formData.phone})) {
                return this.$message({
                    type: "warning",
                    message: "电话号码格式不正确",
                });
            }
            let authCode = this.authCode;
            // if (authCode.value) {
            authCode.show = false;
            let data = {
                phoneNumber: this.formData.phone,
                verificationCode: authCode.value,
                roleType: 2,
                uuid: authCode.uuid,
                registerSts:1
            };
            this.getCodePhone.statu = true;
            debugger;
            phoneRegister(data).then(
                (res) => {
                    if (res.code === SUCCESS_CODE) {
                        this.getCodePhone.statu = false;
                        this.getCodePhone.show = true;
                        this.getCodePhone.disabled = true;
                        this.getCodePhone.text = "重新发送";
                        let codeNmu = setInterval(() => {
                            this.getCodePhone.num--;
                            if (this.getCodePhone.num < 1) {
                                clearInterval(codeNmu);
                                this.getCodePhone.text = "重新发送";
                                this.getCodePhone.show = false;
                                this.getCodePhone.disabled = false;
                                this.getCodePhone.num = 60;
                            }
                        }, 1000);
                    }
                },
                (error) => {
                    this.getCodePhone.statu = false;
                    this.$forceUpdate();
                }
            );
            // }
        },
        //提交表单
        submitForm() {
            this.submitLoading = true;
            let formRuleName = ["userForm"];
            let _this = this;
            let data = this.formData;
            let statu = formRuleName.map((item) => {
                let _s = Boolean;
                this.$refs[item].validate((valid) => {
                    if (valid) {
                        _s = true;
                    } else {
                        _s = false;
                    }
                });
                return _s;
            });
            statu = statu.every((item) => item === true);

            if (statu) {
                if (!this.agreeStatu){
                    this.submitLoading = false;
                    return this.$message({
                        type: "warning",
                        message: "请勾选服务协议！",
                    });
                }
                web_common_userBuyer_register(data).then((res) => {
                    this.submitLoading = false;
                    _this.$router.push({
                        // name: "/distributor/h5-registration-success"
                        path: "/distributor/h5-registration-success"
                    });

                }).catch((err) => {
                    this.submitLoading = false;
                    return this.$message({
                        type: "warning",
                        message: "服务异常，请稍后再试",
                    });
                });

            } else {
                this.submitLoading = false;
                this.$nextTick(() => {
                    let firstEle = document.getElementsByClassName(
                        "el-form-item__error"
                    )[0];
                    document.documentElement.scrollTop =
                        document.documentElement.scrollTop +
                        firstEle.getBoundingClientRect().top -
                        firstEle.parentElement.offsetHeight -
                        18;
                });
            }
        },
    },
};
